import { faLaravel, faReact } from "@fortawesome/free-brands-svg-icons";
import { faDatabase, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { default as React } from "react";
import { useHistory } from "react-router";
import devlights from "../assets/devlights.svg";
import gag from "../assets/gag.png";
import ich from "../assets/ich.jpg";
import tb from "../assets/logo.png";
import pbl from "../assets/LogoBlack.svg";
import td from "../assets/Logo_TD.svg";
import mintec from "../assets/mint-ec.png";
import mongodb from "../assets/mongodb.svg";
import nestjs from "../assets/nestjs.svg";
import Circle from "../Circle";
import Line from "../Line";
import hs from "../assets/hs.png";
import irt from "../assets/irt.png";
import metropolia from "../assets/metropolia_s_white_eng.png";
import mobiletronics from "../assets/mobiletronics.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: `100%`,
    position: "relative",
  },
  helperText: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1) + 80,
    opacity: 0.3,
    color: "white",
  },
}));
function Graph(props: React.HTMLAttributes<HTMLDivElement>) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={clsx(classes.mainContainer, props.className)}>
      <Circle tilt id="me" radius={9} x={10} y={10}>
        <img alt="me" style={{ height: "100%" }} src={ich} />
      </Circle>
      <Circle id="td" radius={6} x={6} y={9.5}>
        <img
          alt="teckdigital"
          style={{ width: "85%", objectFit: "contain" }}
          src={td}
        />
      </Circle>
      <Circle
        onClick={() => history.push("/projects#teckboard")}
        id="tb"
        radius={4}
        x={4}
        y={6}
      >
        <img
          alt="teckboard"
          style={{ width: "100%", objectFit: "contain" }}
          src={tb}
        />
      </Circle>
      <Circle
        onClick={() => history.push("/projects#pbl")}
        id="pbl"
        radius={4}
        x={4}
        y={13}
        style={{ background: "transparent" }}
      >
        <img alt="pick-by-light" src={pbl} />
      </Circle>
      <Circle
        onClick={() =>
          window.open("https://www.hs-osnabrueck.de/", "_blank")?.focus()
        }
        id="hs"
        radius={6}
        x={15}
        y={14.5}
      >
        <img src={hs} width="90%" alt="Hochschule Osnabrück"></img>
      </Circle>
      <Circle
        onClick={() =>
          window.open("https://www.metropolia.fi/en", "_blank")?.focus()
        }
        id="metropolia"
        radius={4.5}
        x={16.5}
        y={11}
      >
        <img src={metropolia} width={"85%"} alt="Metropolia UAS" />
      </Circle>
      <Circle
        onClick={() =>
          window.open("https://www.irt-electric.de", "_blank")?.focus()
        }
        id="irt"
        radius={4}
        x={18}
        y={15}
      >
        <img alt="Ignition Racing Team" src={irt} style={{ width: "75%" }} />
      </Circle>
      <Circle
        id="gag"
        radius={5}
        x={13}
        y={6}
        onClick={() =>
          window.open("https://www.graf-adolf-gymnasium.de", "_blank")?.focus()
        }
      >
        <img alt="graf-adolf-gymnasium" src={gag} style={{ width: "85%" }} />
      </Circle>
      <Circle id="laravel" radius={3} x={3.5} y={3}>
        <FontAwesomeIcon icon={faLaravel} color="rgb(255, 45, 32)" size="3x" />
      </Circle>
      <Circle id="mysql" radius={3} x={1} y={6.5}>
        <FontAwesomeIcon color="#4479a1" icon={faDatabase} size="3x" />
      </Circle>
      <Circle id="react" radius={3} x={1.5} y={4}>
        <FontAwesomeIcon icon={faReact} color="#61dafb" size="3x" />
      </Circle>
      <Circle id="laravel2" radius={3} x={2.5} y={15}>
        <FontAwesomeIcon icon={faLaravel} color="rgb(255, 45, 32)" size="3x" />
      </Circle>
      <Circle id="mysql2" radius={3} x={1} y={12.5}>
        <FontAwesomeIcon icon={faDatabase} color="#4479a1" size="3x" />
      </Circle>
      <Circle
        id="mintec"
        radius={3}
        x={13.5}
        y={2}
        style={{ overflow: "visible" }}
        onClick={() => window.open("https://mint-ec.de", "_blank")?.focus()}
      >
        <img alt="mint-ec" src={mintec} style={{ height: "100%" }} />
      </Circle>
      <Circle
        id="devlights"
        radius={4}
        x={15}
        y={7}
        style={{ backgroundColor: "transparent" }}
        onClick={() => history.push("/projects#devlights")}
      >
        <img alt="devlights" src={devlights} style={{ height: "100%" }} />
      </Circle>
      <Circle id="react2" radius={3} x={18} y={9}>
        <FontAwesomeIcon icon={faReact} color="#61dafb" size="3x" />
      </Circle>
      <Circle id="nestjs" radius={3} x={16} y={3.5}>
        <img alt="nestjs" src={nestjs} style={{ height: "75%" }} />
      </Circle>
      <Circle id="mongo" radius={3} x={18.5} y={4}>
        <img alt="mongo" src={mongodb} style={{ height: "80%" }} />
      </Circle>
      <Circle id="esp" radius={3} x={19} y={6.5}>
        <FontAwesomeIcon icon={faMicrochip} color="#3b4bff" size="3x" />
      </Circle>

      <Circle
        onClick={() => {
          window.open("https://mobiletronics.net", "_blank")?.focus();
        }}
        id="mobiletronics"
        radius={6}
        x={7}
        y={14.5}
      >
        <img src={mobiletronics} alt="Mobiletronics" width="90%" />
      </Circle>
      <Line start="me" end="td" />
      <Line start="me" end="gag" />
      <Line start="me" end="hs" />
      <Line start="mobiletronics" end="me" />

      <Line start="td" end="tb" />
      <Line start="td" end="pbl" />

      <Line start="pbl" end="mysql2" />
      <Line start="pbl" end="laravel2" />

      <Line start="tb" end="mysql" />
      <Line start="tb" end="laravel" />
      <Line start="tb" end="react" />

      <Line start="gag" end="devlights" />
      <Line start="gag" end="mintec" />

      <Line start="hs" end="irt" />
      <Line start="hs" end="metropolia" />

      <Line start="devlights" end="nestjs" />
      <Line start="devlights" end="mongo" />
      <Line start="devlights" end="esp" />
      <Line start="devlights" end="react2" />
      <Typography className={classes.helperText}>
        (Click the bubbles to get more information)
      </Typography>
    </div>
  );
}

export default Graph;
